import { setModalWithData, useAppConfigurationSelector, useModalSelector, useTranslate } from "front";
import { translations } from "../../../../translations";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAppDispatch, ReduxStoreState } from "redux/store";
import { SubmitHandler, useForm } from "react-hook-form";
import { useGroupsCache } from "../../../../hooks/useQuery/useGroupsCache";
import { useUsersCache } from "../../../../hooks/useQuery/useUsersCache";
import { Avatar, DropdownProps, Flex, InputProps, Text } from "@fluentui/react-northstar";
import { useParticipantsCache } from "../../../../hooks/useQuery/useParticipantsCache";
import { IContact } from "../../../../interfaces/IContact/IContact";
import { queryClient } from "../../../../index";
import { QUERY_KEY_USERS } from "../../../../const/queryKey";
import { IGroup } from "../../../../interfaces/IGroup/IGroup";
import { COUNTER_BEFORE_SEARCH } from "../../../../const/const";
import { searchAllContacts } from "../../../../apis/actions/contactAction";
import { useContactListCache } from "../../../../hooks/useQuery/useContactListCache";

export const useGroup = () => {
  const t = useTranslate(translations);
  const dispatchCtx = useDispatch<IAppDispatch>();

  // Selector
  const { data } = useModalSelector("data");
  const { appId } = useAppConfigurationSelector("appId");
  const pagination = useSelector((s: ReduxStoreState) => s.pagination);

  // Caches
  const { queryGroups, mutateCreateGroup, mutateEditGroup } = useGroupsCache();
  const { queryUsers } = useUsersCache();
  const { queryParticipants } = useParticipantsCache(data.group?.id);
  const { queryContactList } = useContactListCache();

  // States
  const [participants, setParticipants] = useState<IContact[]>([]);
  const [currentSelectedParticipants, setCurrentSelectedParticipants] = useState<IContact[]>([]);
  const [dropdownValue, setDropdownValue] = useState<any>([]);
  const [bannerIsOpen, setBannerIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [contacts, setContacts] = useState<IContact[]>([]);

  // Refs
  const initialContacts = useRef<IContact[]>(queryUsers.data?.contacts ?? []);

  useEffect(() => {
    if (data.isEditing) return;
    setTimeout(() => {
      trigger("name", { shouldFocus: true });
    }, 10);
  }, [data.isEditing]);

  useEffect(() => {
    if (!queryParticipants.data) return;
    setParticipants(queryParticipants.data);
  }, [queryParticipants.data]);

  const getGroup = useMemo(() => {
    if (!queryGroups.data || !data.group?.id) return undefined;
    return queryGroups.data.find((g) => g.id === data.group.id);
  }, [queryGroups.data, data.group?.id]);

  const {
    formState: { errors, isValid, isDirty },
    control,
    trigger,
    handleSubmit,
  } = useForm<{ name: string; participants: { add: string[]; remove: string[] } }>({
    mode: "onChange",
    defaultValues: {
      name: getGroup?.name ?? "",
      participants: {
        add: [],
        remove: [],
      },
    },
  });

  const handleClose = useCallback(() => dispatchCtx(setModalWithData({ isOpen: undefined, data: undefined })), []);

  useEffect(() => {
    if (!search) return;
    const timeout = setTimeout(() => {
      onSearchContact();
    }, COUNTER_BEFORE_SEARCH);
    return () => clearTimeout(timeout);
  }, [search]);

  const onSearchContact = async () => {
    if (!queryContactList.data) return;
    const params = { contactListId: queryContactList.data.id, search };
    const searchedContacts = await searchAllContacts(params);
    const {result} = searchedContacts;
    setContacts([...result]);
  };

  const handleSearch = (_: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element> | null, data: DropdownProps) => {
  setSearch(data.searchQuery ?? "");
  };
  
  const handleDropdown = (_: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element> | null, data: DropdownProps, field: any) => {
    if (!queryUsers.data) return;
    const ids = (data.value as any).map((g: any) => {
      if (typeof g === "string") {
        const selectedUser = initialContacts.current.find((i) => i.name === g);
        return selectedUser?.id;
      }
      return g.id;
    });
    field.value.add = ids;
    field.onChange(field.value);
    setDropdownValue(data.value);
    updateCurrentParticipants(ids);
  };

  const handleRemove = (id: string, field: any) => {
    if (!queryUsers.data) return;
    field.value.remove.push(id);
    field.onChange(field.value);
    const currentIds = currentSelectedParticipants.map((p) => p.id);
    const filteredIds = currentIds.filter((p) => p !== id);
    updateCurrentParticipants(filteredIds);
    setDropdownValue((prev: any[]) => {
      const target = prev.map((p) => {
        if (typeof p === "string") {
          const contact = initialContacts.current.find((i) => i.name === p);
          return contact?.id === id ? undefined : p;
        } else {
          return p.id === id ? undefined : p;
        }
      });
      const filteredTarget = target.filter((val) => val);
      return filteredTarget;
    });
  };

  const updateCurrentParticipants = (ids: string[]) => {
    const currentParticipants = initialContacts.current.filter((p) => ids.includes(p.id)) ?? [];
    setCurrentSelectedParticipants(currentParticipants);
  };

  const onSubmit: SubmitHandler<{ name: string; participants: { add: string[]; remove: string[] } }> = useCallback(
    async (formData: { name: string; participants: { add: string[]; remove: string[] } }) => {
      try {
        if (data.isEditing) {
          const params = { ...data.group, name: formData.name, participants: formData.participants, contactListId: appId };
          await mutateEditGroup.mutateAsync(params, { onSuccess: () => updateUser(data.group.id, formData.participants) });
          queryParticipants.refetch();
        } else {
          const params = { name: formData.name, participants: formData.participants, contactListId: appId };
          await mutateCreateGroup.mutateAsync(params, { onSuccess: (res: IGroup) => updateUser(res.id, formData.participants) });
        }
      } catch (error) {
        console.log(error);
      } finally {
        handleClose();
      }
    },
    []
  );

  const updateUser = (groupId: string, participants: { add: string[]; remove: string[] }) => {
    queryClient.setQueriesData([QUERY_KEY_USERS, appId], (oldData: { total: number; contacts: IContact[] } | undefined) => {
      if (!oldData) return { total: 0, contacts: [] };
      var updatedContacts = oldData.contacts.map((i) => (participants.add.includes(i.id) ? updateUserGroup(i, groupId) : i));
      return {
        ...oldData,
        contacts: updatedContacts,
      };
    });
  };

  const updateUserGroup = (contact: IContact, groupId: string): IContact => {
    const initialGroups = contact.groupId.split(", ") ?? [];
    const newItem = [...initialGroups, groupId];
    const group = newItem.join(", ");
    return { ...contact, groupId: group };
  };

  const handleInput = (
    _: unknown,
    data:
      | (InputProps & {
          value: string;
        })
      | undefined
  ) => {
    if (typeof data?.value !== "string") return;
    const filteredParticipants = queryParticipants.data?.filter((p) => p.name.toLocaleLowerCase().includes(data.value.toLocaleLowerCase())) ?? [];
    setParticipants(filteredParticipants);
  };

  const handleInputValidation = (value: string) => {
    if (data.group) {
      return !queryGroups.data.find((g) => g.name.toLocaleLowerCase() === value.toLocaleLowerCase() && g.id !== data.group.id);
    } else {
      return !queryGroups.data.find((g) => g.name.toLocaleLowerCase() === value.toLocaleLowerCase());
    }
  };

  const handleDropdownItems = () => {
    if (!contacts || contacts.length === 0) return [];
    const currentParticipantsIds = participants.map((p) => p.id);
    const filteredContact = contacts.filter((qu) => !currentParticipantsIds.includes(qu.id)) ?? [];
    const items = filteredContact.map((c, idx) => ({
      id: c.id,
      header: c.name,
      key: idx,
      content: (
        <>
          <Flex key={c.id} hAlign="start" vAlign="center" gap="gap.small">
            <Avatar image={c.picture} name={c.name.toUpperCase()} size="smallest" />
            <Text content={c.name} />
          </Flex>
        </>
      ),
    }));

    return items;
  };

  const closeBanner = useCallback(() => setBannerIsOpen(false), []);

  const openBanner = useCallback(() => setBannerIsOpen(true), []);

  return {
    t,
    handleClose,
    isEditing: data.isEditing,
    errors,
    control,
    isLoading: mutateCreateGroup.isLoading || mutateEditGroup.isLoading,
    handleSubmit,
    onSubmit,
    isValid,
    isDirty,
    contacts: queryUsers.data?.contacts ?? [],
    handleSearch,
    participants,
    handleDropdown,
    handleRemove,
    handleInput,
    handleInputValidation,
    groups: queryGroups.data,
    handleDropdownItems,
    currentSelectedParticipants,
    dropdownValue,
    setDropdownValue,
    closeBanner,
    openBanner,
    bannerIsOpen,
    searchedContacts: contacts
  };
};
